.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  // max-width: 300px;
  // margin-left: 10px;
  text-align: center;
  font-family: arial;
  height: 100%;
  // justify-content: center;
  align-items: center;
  padding: 10px;
  h1 {
    font-size: 20px;
    color: #212529;
    margin-bottom: 25px;
    margin-top: 10px;
  }
  p {
    font-size: 12px;
    margin-bottom: 90px;
    color: #212529;
  }
  a {
    text-decoration: none;
  }
  button {
    background: #193c4f;
    border-radius: 5px;
    position: absolute;
    width: 90%;
    bottom: 5%;
    border: none;
    outline: 0;
    padding: 12px;
    color: white;
    text-align: center;
    cursor: pointer;
    font-size: 18px;
  }
  .product_img {
    height: 240px;
    width: auto;
    overflow: hidden;
    margin-bottom: 5px;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

.card button:hover {
  opacity: 0.7;
}
.product-image {
  // width: 300px!important;
  height: 300px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.new-tag {
  top: -2%;
  right: -5%;
  background-color: rgb(194, 4, 4);
  border-radius: 60%;
  span {
    font-size: 10px;
  }
}
