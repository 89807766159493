.user-name {
  line-height: 35px;
  margin-left: 10px;
}

.change-pic {
  line-height: 35px;
}

.save-btn {
  background: $mainTheme !important;
  border: 1px solid $mainTheme !important;
  color: #fff !important;
  padding: 5px 8px !important;
}

.input-edit {
  border: 1px solid #ccc;
  width: 90%;
  border-radius: 15px;
  padding: 0 10px;
}

.search-bar {
  border: 1px solid #ccc;
  width: 95%;
  border-radius: 15px;
  margin: 10px auto;
  padding: 2px 10px;
  justify-content: center;

  input {
    width: 90%;
    background: none;
    border: none;
    color: #fff;
  }
  :focus {
    outline: none;
  }
  i {
    color: #fff;
  }
}

.img-upload {
  background-color: rgba(255, 255, 255, 0.5);
  border-style: dashed;
  height: inherit;
  width: inherit;
  border-radius: inherit;

  i {
    color: white;
    opacity: 75%;
  }
}

.contact {
  margin: auto 10px !important;
  p {
    font-size: 16px !important;
  }
}

.btn--general {
  position: relative !important;
  width: 100%;
  padding: 10px;
  color: #fff;
  text-align: center;
  background: #0d3347 !important;
  border-radius: 30px;
  display: inline-block;
  margin-top: 20px;
}

.green {
  color: #4caf50;
}

.red {
  color: #dc143c;
}

.white {
  color: #fff;
}

.card--reset {
  width: 500px;
  margin: 0 auto;
}

.no-data-wrapper {
  padding: 40px 32px;
  text-align: center;
  border-bottom: 0;
}

.no-data-icon {
  width: 80px;
  height: 69px;
  &.wave {
    fill: #d1dae6;
    transform: rotate(-25deg);
    &:hover {
      fill: #fbb432;
    }
  }
}

.no-data-wrapper span {
  margin-top: 12px;
  color: #9faab7;
  font-size: 14px;
  display: inline-block;
}

.pointer {
  cursor: pointer;
}

.custom-file-upload {
  display: inline-block;
  font-size: 25px;
  margin: 0px 5px;
  color: #193c4f;
  padding: 0 5px;
  cursor: pointer;
}

.custom-file-upload input[type='file'] {
  display: none;
}

.clearfix {
  overflow: auto;
}

.attachment-highlight {
  font-weight: bold;
  img {
    height: auto;
    max-width: 100%;
  }
}

.ta {
  width: 93%;
  margin: 10px;
  outline: none;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #ccc;
  resize: none;
  height: 200px;
  font-size: 15px;
}

.title-input {
  width: 93% !important;
  margin: 10px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #ccc;
  resize: none;
  font-size: 15px;
}

.cirwidth {
  width: 3% !important;
}

.uploader {
  background: linear-gradient(270deg, #888988, #ffffff);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 2s ease infinite;
  -moz-animation: AnimationName 2s ease infinite;
  animation: AnimationName 2s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.btn--no-decor {
  outline: none;
  background: transparent;
  border: none;
  padding: 12px 5px;
  color: white;
}

.multiline {
  white-space: pre-wrap;
  word-break: break-all;
  padding: 5px;
}

.border--none {
  border: none !important;
}

.user--pic {
  background-color: #0d3347;
  width: 40px;
  height: 40px;
  // line-height: 40px;
  border-radius: 50%;
  overflow: hidden;
  // float: left;
  // display: flex;
  // margin-right: 15px;
  // border: 1px solid #212529;
  align-items: center;
}

.user--pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.msgbox-span-clear {
  position: relative !important;
  top: 0px !important;
}

.highlight-search {
  background-color: #fbb432;
}

.found-number {
  font-size: 12px;
}
.navigaton {
  .chat_window {
    position: relative;
    .notification {
      position: absolute;
      background: red;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 20px;
      right: 9px;
      font-size: 9px;
      padding: 0px;
    }
  }
}

.swal-button--confirm {
  background-color: #0d3347;
}

.swal-button--cancel {
  color: white;
  background-color: #dc143c;
}

.group-members {
  color: #07a8ff;
  font-weight: 500;
  font-size: 12px;
}

.meta-wrapper {
  display: grid;
  grid-template-columns: 20fr 80fr;
  padding: 8px 4px;
  max-width: 700px;
  column-gap: 10px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;

    .content-title {
      font-weight: 600;
      text-align: left;
      font-size: 16px;
      color: $mainTheme;
    }
    .content-description {
      font-weight: 500;
      text-align: left;
      color: $mainTheme;
    }
  }
}
