.wrapper {
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: #0d3347;
  align-items: center;
  justify-content: center;
}

.content_wrapper {
  padding: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  color: white;
  font-size: 42px;
  font-weight: 700;
}

.subheading {
  color: white;
  font-size: 28px;
  font-weight: 300;
}

.app_download_group {
  margin-top: 20px;

  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.download_btn {
  padding: 12px;
  border-radius: 8px;
  display: flex;
  background-color: black;
  text-decoration: none;
  cursor: pointer;
}

.download_btn:hover {
  text-decoration: none;
}

.download_btn_content {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  color: white;
  font-size: 18px;
}

.download_btn_content_img {
  width: 32px;
  height: 32px;
}
