/* ----- min-width ----- //*/

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
}

/* ----- max-width ----- //*/

/* Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

@media (max-width: 1024px) {
}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .sidebar {
    @include flexbox();
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 99;
    // margin-left: -100%;
    transition: 500ms;
    &.show-chat {
      margin-left: -100%;
    }
  }
  .loginFormBox {
    display: none;
  }
  .loginlogo {
    form {
      padding: 20px;
      width: 320px;
    }
  }
}

@media (max-width: 320.98px) {
}

/* ----- min-width & max-width ----- //*/

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Portrait and Landscape */
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  users {
    .collase-user {
      display: block;
    }
  }
  .loginFormBox {
    display: none;
  }
  .loginbox {
    padding: 0px;
    position: relative;
    height: 100vh;
    .loginlogo {
      height: auto;
      position: absolute;
      padding: 25px;
      left: 42%;
      top: 25%;
      box-shadow: 12px 8px 14px #000;
    }
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .users {
    .collase-user {
      display: block;
    }
  }
}
