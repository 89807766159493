.users {
  float: left;
  height: calc(100vh - 60px);
  overflow: hidden;
  background: #193c4f;
  width: 450px;
  @include vendorPrefix(transition, 0.2s);
  position: relative;
  border-style: solid;
  border-width: 0 2px 0 1px;
  border-color: white;
  border-left-color: #736565;

  .usertitle {
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: #736565;
    height: 60px;
    h5 {
      padding: 15px;
      color: #fff;
    }
  }
  .collase-user {
    position: absolute;
    color: #47db56;
    width: 40px;
    height: 40px;
    background: #193c4f;
    border-radius: 50%;
    right: -12px;
    z-index: 99;
    text-align: left;
    line-height: 38px;
    top: 50%;
    padding: 0px 0px 0 8px;
    cursor: pointer;
    font-size: 28px;
    border: 2px solid #fff;
    display: none;
    // border-style: solid;
    // border-width: 2px;
    // border-color: white;
  }
  .chatlist {
    overflow-y: auto;
    // height: calc(100vh - 80px);
    height: calc(100vh - 175px);
    padding-bottom: 10px;
    overflow-x: hidden;
    ul {
      padding: 0px;
      li {
        // padding: 5px;
        position: relative;
        display: flex;
        .msg-receive-notice {
          height: 10px;
          width: 10px;
          background: red;
          border-radius: 50%;
          position: absolute;
          right: 10px;
          top: 25%;
        }
        .smaller {
          top: 10%;
          left: 5px;
        }
        a {
          padding: 5px;
          width: 100%;
          display: inline-flex;
          &.active {
            background: #1f9fe9;
          }
        }
        .avatar-box {
          display: inline-block;
          position: relative;
          height: 40px;
          .avatar {
            width: 40px;
            height: 40px;
            // line-height: 40px;
            border-radius: 50%;
            float: left;
            position: relative;
            .profile-img {
              position: relative;
              overflow: hidden;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              border: 1px solid #fff;
              display: flex;
              align-items: center;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            span.status {
              position: absolute;
              bottom: 3px;
              right: 0px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              &.online {
                background: #04bf4f;
              }
              &.away {
                background: #fbb432;
              }
              &.offline {
                background: #fff;
              }
            }
          }
        }
        .userinfo {
          margin-left: 5px;
          display: inline-block;
          color: #fff;
          p {
            text-transform: capitalize;
            padding: 0px;
            margin: 2px;
            font-size: 16px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
          }
        }
        &.current-chat {
          background: #00486f;
        }
        &:hover {
          background: #00486f;
        }
        .contact_options {
          position: absolute;
          top: 0px;
          right: 5px;
          a {
            width: auto;
            margin-right: 5px;
          }
        }
      }
      .time {
        position: absolute;
        right: 10px;
        bottom: 6px;
        color: #fff;
        font-size: 10px;
      }
      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
    &.contactlist {
      ul {
        li {
          padding: 5px;
        }
      }
    }
  }
  &:hover {
    .collase-user {
      display: block;
    }
  }
}

.open {
  margin-left: 0px;
  width: 65px;
  .chatlist {
    // display: none;
    ul {
      li {
        .userinfo,
        .time {
          display: none;
        }
        a {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .search-bar {
    display: none;
  }
}
