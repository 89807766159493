.right-column {
  .main-content {
    height: calc(100vh - 50px);
    overflow: auto;
  }
}
.profile-modal {
  .profile_cls {
    padding: 10px 0;
    width: 500px;
    max-width: 100%;
    margin: 0px auto;
    .w-header {
      background: $mainTheme;
      padding: 10px;
      text-align: right;
      .fa {
        color: #fff;
      }
    }
    .profile-head {
      padding: 10px;
      display: flex;
      border-bottom: 1px solid $mainTheme;
      .onlineuser {
        width: 20%;
        .avatar {
          width: 80px;
          height: 80px;
          background: $mainTheme;
          border-radius: 50%;
          // line-height: 80px;
          margin-right: 20px;
          overflow: hidden;
          text-align: center;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // position: absolute;
          }
        }
        .btn-save {
          background: #193c4f;
          padding: 5px 20px;
          color: #fff;
          border: 1px solid #193c4f;
          margin-top: 20px;
        }
      }
      .profile-name {
        width: 80%;
        p {
          button {
            float: right;
            background: $mainTheme;
            border: 1px solid $mainTheme;
            color: #fff;
            padding: 5px 8px;
          }
        }
        h4 {
          word-wrap: break-word;
        }
        .profile-update-btn {
          margin-bottom: 5px;
          .save-btn {
            margin-right: 5px;
          }
          .btn-cancel {
            background: #af0202;
            color: #fff;
            border: 1px solid #af0202;
          }
        }
      }
    }
    .profile-info {
      padding: 10px 0;
    }
    .profile-chng-pwd {
      button {
        &.btn-save {
          padding: 3px 10px;
          background: $mainTheme;
          border: 1px solid $mainTheme;
          color: #fff;
        }
      }
    }
  }
}
