.navigaton {
  float: left;
  // height: 100vh;
  height: calc(100vh - 60px);
  overflow: hidden;
  background: #0d3246;
  // flex: 60px 0px 0px;
  width: 60px;
  flex-shrink: 0;
  z-index: 9999;
  ul {
    padding: 0px;
    text-align: center;
    color: #fff;
    li {
      a {
        color: #fff;
        font-size: 20px;
        padding: 15px 0;
        display: block;
        &.active {
          background: #193c4f;
        }
      }
      &:hover {
        background: #193c4f;
      }
    }
  }
  .logo {
    // background: #fff;
    padding: 8px 4px;
    height: 60px;
  }
}
