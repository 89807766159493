//COLORS
$mainTheme: #193c4f;
$lightTheme: #f0e7ed;
$lightGray: #f7f7f7;
$color-bg: #010717;
$color-highlight: #217b94;
$brown: #942529;
$textColor: #333;
$white: #fff;
$red: #ff0000;
$black: #000000;
$dark: #1d1b1b;
$gold: #bc8e53;
$mediumGray: #a6a6a6;
$green: #0f9187;

///////////////////
$primary: black;
$secondary: #c9caca;

$link-color: $primary;
$link-hover-decoration: none;

//////////////////////

// $kairi:      #d56a58;
// $sl-gunma:   #bfb174;
// $izu:        #ee788e;
// $tohoku:     #727272;
// $shukura:    #c30f24;
// $fruitea:    #c9a063;
// $genbi:      #1a306f;

// $shirakami: #601986;
// $minori: #f8b62d;
// $toreiyu: #8fc31f;
// $pokemon: #000000;
// $sl-ginga: #814e21;
// $sl-banetsu: #f39800;
// $highrail: #1f9bd8;
// $furusato: #006835;
// $oykot: #814e21;

//FONTS
$size: 16px;
$line-height: 1.5;
$font: 'Quicksand', 'EB Garamond', 'KozGoPr6N', 'Stardust Adventure', Geneva, Arial, Verdana, sans-serif !important;
$font-heading: 'EB Garamond', serif;

$blue: #007bff;
$indigo: #6610f2;
$purple: #7159a5;
$pink: #ed7980;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$colors: ();
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan // "shirakami": #601986,
      // "minori": #f8b62d,
      // "toreiyu": #8fc31f,
      // "pokemon": #000000,
      // "ginga": #814e21,
      // "banetsu": #f39800,
      // "gunma": #c0b174,
      // "highrail": #1f9bd8,
      // "furusato": #006835,
      // "oykot": #814e21,
      // "shukura": #c30d24,
      // "genbi": #231916,
      // "kairi": #ea5515,
      // "izu": #ee788e,
      // "fruitea": #c9a063,
      // "tohoku": #898989,,,,
  ),
  $colors
);

@each $name, $color in $colors {
  .text-#{$name} {
    color: $color;
  }
  .bg-#{$name} {
    background-color: $color;
  }
  .bg-hover-#{$name} {
    &:hover {
      color: white;
      background-color: $color;
    }
  }
  .bg-caption-#{$name} {
    background-image: linear-gradient(to right, rgba($color, 0), rgba($color, 1));
  }
}

$primary: $blue;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;

// $theme-colors: ();
// $theme-colors: map-merge((
//   "primary":    $primary,
//   "success":    $success,
//   "info":       $info,
//   "warning":    $warning,
//   "danger":     $danger,

//   "kairi":      #d56a58,
//   "kairi-70":   transparentize(#d56a58,0.3),

//   "sl-gunma":   #bfb174,
//   "sl-gunma-70":transparentize(#bfb174,0.3),

//   "izu":        #ee788e,
//   "izu-70":     transparentize(#ee788e,0.3),

//   "tohoku":     #727272,
//   "tohoku-70":  transparentize(#727272,0.3),

//   "shukura":    #c30f24,
//   "shukura-70": transparentize(#c30f24,0.3),

//   "fruitea":    #c9a063,
//   "fruitea-70": transparentize(#c9a063,0.3),

//   "highrail": #1f9bd8,
//   "highrail-70":   transparentize(#1f9bd8,0.3),

//   "shirakami": #601986,
//   "shirakami-70":   transparentize(#601986,0.3),

//   "minori": #f8b62d,
//   "minori-70":   transparentize(#f8b62d,0.3),

//   "toreiyu": #8fc31f,
//   "toreiyu-70":   transparentize(#8fc31f,0.3),

//   "pokemon": #000000,
//   "pokemon-70":   transparentize(#000000,0.3),

//   "sl-ginga": #814e21,
//   "sl-ginga-70":   transparentize(#814e21,0.3),

//   "sl-banetsu": #f39800,
//   "sl-banetsu-70":   transparentize(#f39800,0.3),

//   "oykot": #814e21,
//   "oykot-70":   transparentize(#814e21,0.3),

//   "genbi": #231916,
//   "genbi-70":   transparentize(#231916,0.3),

//   "furusato":   #006835,
//   "furusato-70":transparentize(#006835,0.3)
// ), $theme-colors);
// stylelint-enable

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;
