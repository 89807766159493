.fdental-login {
  height: 100vh;
  background: url(../../images/bg.png) no-repeat;
  background-size: cover;
}
.loginFormBox {
  height: 100vh;
  // background: #0d3246;
}
.loginlogo {
  height: 100vh;
  flex-direction: column;
  background: #fff;
  // background: #193c4f;
  .welcomelogin {
    text-align: center;
    img {
      // width: 20%;
    }
  }
  form {
    width: 400px;
    color: #000;
    label {
      .fa {
        font-size: 25px;
      }
    }
    h3 {
      text-align: center;
      padding: 10px 20px;
    }
    .form-control {
      background: transparent;
      border: none;
      color: #000;
      &:focus {
        box-shadow: none;
      }
    }
    .inputbox {
      border-bottom: 1px solid #0d3347;
      display: flex;
      margin-bottom: 10px;
      padding: 0 0 5px 0;
      input {
        flex: auto;
        padding: 5px;
        border: none;
        &:focus {
          outline: none;
        }
      }
    }
    .forgetpwd {
      color: #000;
      text-decoration: underline;
      cursor: pointer;
    }

    .signup {
      color: #000;
      text-decoration: underline;
      cursor: pointer;
      margin-top: 10px;
    }
    .submit-button {
      .submitbtn {
        width: 100%;
        padding: 10px;
        color: #fff;
        text-align: center;
        background: #0d3347;
        border-radius: 30px;
        display: inline-block;
        margin-top: 20px;
        height: 48px;
      }
    }
  }
}

.signupform {
  input[type='radio'] {
    appearance: none;
    width: 16px;
    height: 16px;
    content: none;
    outline: none;
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  input[type='radio']:checked {
    appearance: none;
    outline: none;
    padding: 0;
    content: none;
    border: none;
  }

  input[type='radio']:checked::before {
    position: absolute;

    color: green !important;
    content: '\00A0\2713\00A0' !important;
  }
}

.signup__option {
  display: flex;
  align-items: center;
  input[type='radio'] {
    width: 20px;
    height: 20px;
  }
}

.unsubscribe-block {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.unsubscribe-submit-btn {
  width: 100%;
  padding: 10px;
  color: #fff;
  text-align: center;
  background: #0d3347;
  border-radius: 30px;
  display: inline-block;
  margin-top: 20px;
  height: 48px;
  position: relative !important;
  width: 100% !important;
}
