.header {
  height: 60px;
  background: #10374c;
  border-bottom: 1px solid #414a4e;
  .menubar {
    color: #fff;
  }
  .logobox {
    padding-left: 0px;
  }
  .unserinfo {
    float: right;
    cursor: pointer;
    position: relative;
    min-width: 171px;
    @include flexbox();
    justify-content: center;
    .profile-box {
      position: relative;
      display: inline-block;
      float: left;
    }
    .onlineuser {
      width: 40px;
      height: 40px;
      // line-height: 40px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #fff;
      float: left;
      display: flex;
      align-items: center;
      span {
        &.status {
          position: absolute;
          bottom: 3px;
          right: 0px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
        &.online {
          background-color: #04bf4f;
        }
        &.away {
          background: #fbb432;
        }
        &.offline {
          background: #fff;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    span {
      float: left;
      color: #fff;
      padding: 2px 5px;
      text-transform: capitalize;
      .fa {
        padding: 0 5px;
      }
    }
    .profile-setting {
      /* display: none; */
      width: 120%;
      background: #1b5d82;
      position: absolute;
      top: 50px;
      left: -42px;
      z-index: 9;
      span {
        color: #fff;
      }
      .profile-wrap {
        padding: 5px;
      }
      ul.setting-menu {
        padding: 0;
        margin: 10px 0 0 0;
        display: inline-block;
        list-style: none;
        width: 100%;
        li {
          padding: 5px 8px;
          margin-bottom: 2px;
          a {
            color: #fff;
            &:hover {
              text-decoration: none;
            }
          }
          &:hover {
            background: #0b5077;
          }
          span.statustext {
            padding: 0px 5px 0 0;
          }
          .status {
            position: relative;
            padding-left: 15px;
            &:before {
              content: '';
              position: absolute;
              left: 0px;
              top: 8px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
            }
            &.online {
              &:before {
                background-color: #04bf4f;
              }
            }
            &.offline {
              &:before {
                background-color: white;
              }
            }
            &.away {
              &:before {
                background-color: #fbb432;
              }
            }
          }
        }
      }
    }
  }
  .msgsearch {
    input {
      width: 100%;
      border-radius: 30px;
      padding: 3px 10px;
      border: 1px solid #ccc;
    }
  }
  .notification {
    float: right;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #fff;
    cursor: pointer;
    .counter {
      width: 18px;
      height: 18px;
      background: red;
      border-radius: 50%;
      font-size: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: -27px;
      z-index: 99;
      margin-top: -11px;
    }
  }
}
