@mixin vendorPrefix($property, $args) {
  $prefixes: (webkit moz ms o);
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $args;
  }
  #{$property}: $args;
}

@mixin flexbox {
  display: -webkit-box; // old
  display: -moz-box; // old
  display: -ms-flexbox; // ie
  display: -webkit-flex; // new
  display: flex; // new
}

@mixin transform($args) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}

@mixin transition($prop: all, $duration: 0.2s) {
  transition: $prop $duration cubic-bezier(0.76, 0.52, 0.17, 0.99);
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin linear-gradient($from, $to, $stop: 100%) {
  background-color: $from;
  background-image: -moz-linear-gradient(left, $from 0%, $to $stop);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, $from), color-stop($stop, $to));
  background-image: -webkit-linear-gradient(left, $from 0%, $to $stop);
  background-image: -o-linear-gradient(left, $from 0%, $to $stop);
  background: -ms-linear-gradient(left, $from 0%, $to $stop);
  background: linear-gradient(to right, $from 0%, $to $stop);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to', GradientType=1);
}

@mixin gradient-radial($innerColor, $outerColor) {
  background-color: $outerColor;
  background-image: -webkit-gradient(radial, center center, 0, center center, 460, from($innerColor), to($outerColor));
  background-image: -webkit-radial-gradient(circle, $innerColor, $outerColor);
  background-image: -moz-radial-gradient(circle, $innerColor, $outerColor);
  background-image: -o-radial-gradient(circle, $innerColor, $outerColor);
  background-repeat: no-repeat;
}

@mixin buttonDrawBorder($color: $white, $background: transparent) {
  transition: background 0.5s ease-in 1s, color 0.5s ease 1s;
  position: relative;
  color: $color;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 1px solid transparent;
  }
  &:before {
    top: 0;
    left: 0;
  }
  &:after {
    bottom: 0;
    right: 0;
  }
  &:hover {
    background: $background;
    color: $color;
    &:before,
    &:after {
      width: 100%;
      height: 100%;
    }
    &:before {
      border-top-color: $color;
      border-right-color: $color;
      transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
    }
    &:after {
      border-bottom-color: $color;
      border-left-color: $color;
      transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s, height 0.25s ease-out 0.75s;
    }
  }
}
