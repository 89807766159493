.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.spinner.small {
  width: 14px !important;
  height: 14px !important;
  margin: 0px auto !important;
}

.spinner.large {
  width: 14px !important;
  height: 14px !important;
  margin: 0px auto !important;
}

.spinningCircle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0);
  border-top-color: 4px solid #acc2c7;
  border-right-color: 4px solid #acc2c7;
  -webkit-animation: single2 4s infinite linear;
  animation: single2 4s infinite linear;
}

.spinningCircle.small {
  height: 15px !important;
  width: 15px !important;
  border: 2px solid rgba(255, 255, 255, 0);
}

.spinningCircle.large {
  height: 45px !important;
  width: 45px !important;
  border: 2px solid rgba(255, 255, 255, 0);
}

@-webkit-keyframes single2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border-top-color: #7fc4d1;
    border-right-color: #7fc4d1;
  }
  50% {
    border-top-color: #1f4f58;
    border-right-color: #1f4f58;
  }
  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
    border-top-color: #7fc4d1;
    border-right-color: #7fc4d1;
  }
}

@keyframes single2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border-top-color: #7fc4d1;
    border-right-color: #7fc4d1;
  }
  50% {
    border-top-color: #1f4f58;
    border-right-color: #1f4f58;
  }
  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
    border-top-color: #7fc4d1;
    border-right-color: #7fc4d1;
  }
}
