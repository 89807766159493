.sidebar {
  @include flexbox();
}

.clear-fix:before,
.clear-fix:after {
  content: ' ';
  display: block;
}
.clear-fix:after {
  clear: both;
}

p.prewrap {
  white-space: pre-wrap;
}

.is_open {
  // .logo{
  //     padding: 0px 15px;
  // }
  .usertitle {
    h5 {
      display: none;
    }
  }
  .search-bar {
    display: none;
  }
}

.fdentalbox {
  @include flexbox();
}
.right-column {
  float: left;
  height: calc(100vh - 60px);
  overflow: hidden;
  background: #fff;
  width: 100%;
  position: relative;
  .chatwelcomebox {
    height: 100%;
    width: 100%;
    @include flexbox();
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    .activeuser {
      .onlineuser {
        position: relative;
        width: 80px;
        height: 80px;
        line-height: 80px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #fff;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          vertical-align: top;
        }
      }
    }
    .msg {
      padding: 10px 0;
      text-align: center;
      font-weight: bold;
    }
  }
  .chatbox {
    width: 100%;
    height: calc(100vh - 60px);
    position: relative;
    .chatinput {
      width: 100%;
      bottom: 0;
      position: absolute;
      padding: 10px;
      background: #fff;
      @include flexbox();
      flex-direction: row;
      vertical-align: middle;
      align-items: center;
      textarea {
        width: 90%;
        outline: none;
        padding: 5px;
        border-radius: 10px;
        border: 1px solid #ccc;
        resize: none;
        height: 50px;
        font-size: 15ox;
      }
      button {
        padding: 0px;
        background: transparent;
        border: none;
        .fa {
          font-size: 25px;
          margin: 0px 5px;
          color: #193c4f;
          padding: 0 5px;
        }
      }
      .send-btn {
        &:focus {
          outline: none;
          box-shadow: none;
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
    .msgsearchbox {
      background: #fff;
      width: 100%;
      z-index: 10;
      transition: width 2s;
      background: #d3eaf7;
      .msgsearch {
        padding: 10px;
        .close {
          float: right;
          margin-left: 20px;
        }
        input {
          outline: none;
          border: 1px solid #ccc;
          width: 82%;
          border-radius: 15px;
          padding: 3px 10px;
        }
      }
    }
    .chathead {
      padding: 9px 0px;
      border-bottom: 1px solid #193c4f;
      .userinfo {
        color: #000;
        display: flex;
        justify-content: space-between;
        .userbar {
          display: flex;
          align-items: center;
          margin-left: 10px;
          .userimagebox {
            position: relative;
          }
          span {
            // width: 10px;
            &.status {
              position: absolute;
              margin-left: 2px;
              bottom: 3px;
              right: 0px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
            }
            &.online {
              background-color: #04bf4f;
            }
            &.away {
              background: #fbb432;
            }
            &.offline {
              background: #fff;
            }
          }
          .username {
            margin-left: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;

            .grp_tooltip {
              padding: 5px 30px 5px 5px;
              .group_members {
                padding: 6px 6px;
                display: flex;
                flex-direction: column;
                .members {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 8px;
                  .members_status {
                    position: relative !important;
                    bottom: 0px !important;
                    margin-left: 0px !important;
                  }
                }
              }
            }
          }
        }
        .infobar {
          // float: left;
          // width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 8px;
          align-items: center;
          .group-chat {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
            color: #0d3347;
            padding: 8px 4px;
            &:hover {
              cursor: pointer;
              background-color: #0d334722;
              border-radius: 8px;
            }
          }
          .msg-find {
            margin-right: 5px;
            background: #10374c;
            padding: 5px 10px;
            border-radius: 5px;
            color: #fff;
          }
          .username {
            font-size: 20px;
            color: #000;
            // width: 100%;
            // float: left;
            overflow: hidden;
            white-space: pre;
            text-overflow: ellipsis;
          }

          span {
            padding: 0px 8px;
            font-size: 14px;

            &.total_msg {
              border-right: 1px solid #fff;
            }
          }
        }
      }
    }
  }

  .__react_component_tooltip {
    ul {
      list-style-type: none;
      padding-inline-start: 0px;
    }
  }

  .communicationbox {
    overflow: auto;
    height: calc(100vh - 125px - 65px);
    // padding-bottom: 100px;
    // padding-top: 20px;
    position: relative;

    .loadmore {
      position: absolute;
      top: 5px;
      width: 100%;
      text-align: center;
      z-index: 99;
      cursor: pointer;
      button {
        font-size: 12px;
        background: #f5f5f5;
        padding: 5px 10px;
        border-radius: 10px;
        border: none;
        box-shadow: 0px 2px #d1dae6;
        &:focus {
          outline: none;
        }
        &:hover {
          background: #d1dae6;
        }
      }
    }
    .card {
      width: 300px;
      box-shadow: none;
      border: none;
      .productImage {
        border: 1px solid #193c4f;
        border-bottom: none;
        border-radius: 5px 5px 0 0;
        position: relative;
        img {
          object-fit: cover;
        }
        .fa {
          position: absolute;
          right: 5px;
          top: 5px;
        }
      }
      .card-body {
        padding: 10px;
        background: #86cdf7;
        border-radius: 0px 0px 5px 5px;
        border: 1px solid #193c4f;
        border-top: none;
        width: 100%;
        .card-text {
          margin-bottom: 10px;
          padding-bottom: 10px;
        }
        .comment {
          .card-text {
            margin-bottom: 10px;
            border-top: 2px solid #193c4f;
            padding-bottom: 10px;
          }
          p {
            border: none;
            text-align: left;
            font-size: 14px;
            word-break: break-word;
          }
        }
      }
    }
  }
  .communicationbox-small {
    height: calc(100vh - 125px - 65px - 65px);
  }
}

.appTheme--bg {
  background: #193c4f;
}

.chathistory {
  padding: 4px 20px;
  .onlineuser {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #fff;
    float: left;
    margin-right: 5px;
    flex: 0 0 10px;
    flex-basis: 2.5em;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
  .msgbox {
    // background: red;
    position: relative;
    width: auto;
    padding: 2px 0px 2px 10px;
    border-radius: 30px;
    color: #000;
    max-width: 100%;
    font-size: 14px;
    min-width: 40%;
    word-break: break-all;
    display: flex;
    flex-direction: column;
    span {
      // position: absolute;
      // top: -18px;
      // left: 0;
      // width: 200px;
      color: #000;
      font-size: 14px;
    }
    .usermsg {
      // background: #f5f5f5;
      padding: 5px 20px;
      background: #e4e6eb;
      border-radius: 10px;
      float: left;
      position: relative;
      max-width: 80%;
      white-space: pre-wrap;
      place-self: baseline;
      // text-align: left;
      // white-space: pre;
      //   .time:after {
      //     content:'';
      //     position: absolute;
      //     top: 30%;
      //     left: 100%;
      //     width: 0;
      //     height: 0;
      //     border-left: solid 5px #000;
      //     border-top: solid 5px transparent;
      //     border-bottom: solid 5px transparent;
      // }
    }
    .time {
      padding: 0px 5px;
      background-color: #000;
      opacity: 0;
      color: #fff;
      height: 22px;
      // max-width: 25%;
    }
    .fileupload {
      .filebox {
        display: flex;
        padding: 10px 0;
        margin-bottom: 5px;
        border-bottom: 1px solid #ccc;
        .attachment-highlight {
          width: 250px;
        }
        button {
          background: transparent;
          border: none;
          position: absolute;
          z-index: 100;
          top: 0px;
          background-color: #193c4f;
          border-radius: 50%;
          height: 30px;
          width: 30px;
          outline: none;
          .fa {
            color: #ffffff;
            font-size: 16px;
          }
        }
        .btn-right {
          right: -17px !important;
        }
        .btn-left {
          left: -9px !important;
        }
      }
    }
    &:hover {
      .time {
        opacity: 0.75;
      }
    }
  }
  &.othermsg {
    @include flexbox();
    position: relative;
    // flex-direction: row;
    // .msgbox {
    //   background: #f2f6f9;
    //   overflow-wrap: anywhere;
    // }
    .msgseen {
      position: absolute;
      left: 80px;
      bottom: -2px;
      font-size: 10px;
      color: #7575a1;
    }
    // .ursermsg {
    //   &:hover {
    //     .time {
    //       position: absolute;
    //       right: -10%;
    //       top: 5px;
    //       display: block;
    //     }
    //   }
    // }
    .time {
      margin-left: 10px;
    }
    .msgbox {
      margin-bottom: 10px;
    }
  }
  &.mymsg {
    @include flexbox();
    flex-direction: row;
    padding: 8px 20px;
    justify-content: flex-end;
    position: relative;
    .msgbox {
      display: flex;
      flex-direction: column;

      // @include flexbox();
      // flex-direction: column;
      //background: #dbf4fd;
      // color: #000;
      // overflow-wrap: anywhere;
      .time {
        margin-right: 10px;
      }
      .usermsg {
        background: #c4ecf5;
        float: right;
        place-self: flex-end;
      }
      span {
        text-align: right;
      }
      .card {
        width: 300px;
        box-shadow: none;
      }
      .sharebox {
        place-self: flex-end;
      }
    }
    .msgseen {
      position: absolute;
      bottom: -6px;
      right: 24px;
      font-size: 10px;
      color: #7575a1;
    }
    // .ursermsg {
    //   &:hover {
    //     .time {
    //       position: absolute;
    //       left: -75px;
    //       top: 5px;
    //       display: block;
    //     }
    //   }
    // }
  }
}
.mCSB_inside > .mCSB_container {
  margin-right: 0px;
}

// css for product
.product-section {
  padding: 30px 0;
  height: 94vh;
  overflow: auto;
  .card {
    border-radius: 5px;
  }
  .card-body {
    background: rgba(13, 50, 70, 0.1);
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      float: left;
      width: 100%;
      label {
        font-weight: bold;
        width: 100%;
        margin: 0px;
      }
      span {
        width: 100%;
      }
    }
  }
  .product-search {
    padding-bottom: 20px;
    padding-left: 0px !important;
  }
  .action {
    margin-top: 25px;
    text-align: right;
    a {
      button {
        background: #193c4f;
        border-color: #193c4f;
        padding: 3px 20px;
        border-radius: 16px;
      }
    }
    &.back-btn {
      text-align: left;
      margin-bottom: 20px;
    }
  }
  &.product-detail {
    .action {
      button {
        outline: none;
        background: #193c4f;
        border: 1px solid #193c4f;
        padding: 3px 20px;
        border-radius: 16px;
        color: #fff;
      }
    }
  }
}
.chat-des {
  display: flex;
}
.description {
  width: 380px;
  overflow-y: scroll;
  overflow-x: hidden;
  float: right;
  height: calc(100vh - 60px);
  background: #fff;
  border-left: 1px solid #ccc;
  .des-header {
    background: #f5f5f5;
    border-bottom: 1px solid #ccc;
    padding: 15px 10px;
    p {
      margin: 0px;
      span {
        float: right;
        cursor: pointer;
        background: #193c4f;
        padding: 2px 5px;
        color: #fff;
        border-radius: 5px;
      }
    }
  }
  .sub {
    background: #fefefe !important;
  }
  ul {
    padding: 0px;
    list-style: none;
    li {
      background: #ccc;
    }
  }
  .add_des {
    text-align: center;
    p {
      text-align: left;
      padding: 2px;
    }
    .btn {
      line-height: 1;
      margin: 0 5px;
      border-radius: 5px !important;
      &.des-save {
        background: #0d3347;
        padding: 5px 8px;
        border: 1px solid #0d3347;
      }
      &.des-cancel {
        background: #c30303;
        padding: 5px 8px;
        border: 1px solid #c30303;
      }
    }
  }
}
// css for modal
.modal {
  .modal-header {
    background: #0d3347;
    .modal-title {
      color: #fff;
    }
    .close {
      color: #fff;
      opacity: 1;
    }
  }
  .modal-footer {
    justify-content: center;
    .send-btn {
      background: #0d3347;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d1dae6;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d1dae6;
}

.staticmsg {
  z-index: 100;
  position: absolute;
  top: -140px;
  width: 86%;
  background: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  .tab-panel {
    border-bottom: 1px solid #ccc;
    float: left;
    width: 100%;
    height: 50px;
    padding: 10px 0;
    margin-bottom: 5px;
    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;
      li {
        display: inline;
        .btn-emoticon {
          outline: none;
          background: #193c4f;
          padding: 5px 10px;
          border-radius: 30px;
          color: #fff;
          margin-right: 10px;
        }
      }
    }
  }
  .tab-content {
    height: 125px;
    overflow: auto;
    width: 100%;
    float: left;
    padding: 8px 10px;
    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;
      li {
        cursor: pointer;
        background: #193c4f1f;
        padding: 5px 10px;
        margin-bottom: 5px;
        border-radius: 10px;
        &:hover {
          background: #c4ecf5;
        }
      }
    }
  }
}
.show-file-box {
  padding: 2px 10px;
  position: absolute;
  top: -27px;
  background: #fff;
  z-index: 9999;
}
.show-file {
  font-size: 11px;
  border: 1px solid #193c4f;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  margin-right: 10px;
  span.close {
    position: absolute;
    font-size: 14px;
    background: #fb2424;
    width: 13px;
    height: 13px;
    color: red;
    cursor: pointer;
    padding: 0;
    margin: 0;
    line-height: 6px;
    color: #fff;
    padding: 2px;
    font-weight: normal;
    border-radius: 50%;
    text-align: center;
    opacity: 1;
  }
}

// css for product
.image-box {
  .slick-arrow {
    &::before {
      color: red;
    }
  }
}
.thumbbox {
  .thumbs {
    position: absolute;
    width: 92%;
    bottom: 0px;
    .slick-arrow {
      &::before {
        color: red;
      }
    }
  }

  h1 {
    margin-bottom: 20px;
  }
  h2 {
    font-size: 16px;
    font-weight: bold;
  }
  .slick-slide {
    img {
      max-width: 100%;
    }
  }
}
.policy-block {
  padding: 30px 0;
  height: 100vh;
  overflow: auto;
  h2 {
    padding: 10px 0 20px 0;
    text-align: center;
    border-bottom: 1px solid $mainTheme;
    margin-bottom: 30px;
  }
  p {
    font-size: 14px;
    h4 {
      font-weight: bold;
      border-left: 5px solid $mainTheme;
      padding: 5px 10px;
      font-size: 18px;
    }
  }
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: #000;
        font-size: 20px;
        &.active {
          // border-color: $mainTheme;
          border: none;
          background: $mainTheme;
          color: #fff;
        }
      }
    }
  }
  .tab-content {
    padding: 20px;
    border: 1px solid $mainTheme;
  }
}

.shareproduct {
  height: 250px;
  overflow: auto;
}
.sharecomment {
  margin-top: 20px;
}

.group-members {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  .member {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    padding: 4px;
    font-size: 12px;
    color: black;
    background-color: #d4e6f1;
    border: 1px solid #2980b9;
  }
}
